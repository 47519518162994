import request from "@/utils/request"

// 用户列表
export function activitylist(query) {
  return request({
    url: '/activeGinseng/listPage',
    method: "get",
    params: query
  })
}
//活动下架
export function updateOffShelf(data) {
  return request({
    url: '/activeGinseng/activeUnShelve',
    method: "post",
    data
  })
}
//活动发布
export function updateOnShelf(data) {
  return request({
    url: 'activeGinseng/activeGrounding',
    method: "post",
    data
  })
}
//活动冻结
export function freezeActivity(data) {
  return request({
    url: '/activeGinseng/activeFreeze',
    method: "post",
    data
  })
}
//活动解冻
export function cancelFreezeActivity(data) {
  return request({
    url: '/activeGinseng/activeThawing',
    method: "post",
    data
  })
}
//活动新增
export function save(data) {
  return request({
    url: '/activeGinseng/save',
    method: "post",
    data
  })
}
//活动修改
export function update(data) {
  return request({
    url: '/activeGinseng/update',
    method: "post",
    data
  })
}
//活动详情
export function listById(query) {
  return request({
    url: '/activeGinseng/listById',
    method: "get",
    params: query
  })
}
//活动用户报名一览
export function listSingUpPage(query) {
  return request({
    url: '/activeGinseng/listSingUpPage',
    method: "get",
    params: query
  })
}
//活动用户报名一览导出
export function exportExcel(data) {
  return request({
    url: '/activeGinseng/listSingUpExport',
    method: "post",
    responseType: "blob",
    data
  })
}

//统计报名各个状态总数
export function listAll(query) {
  return request({
    url: '/activeGinseng/listAll',
    method: "get",
    params: query
  })
}

//活动报名用户签到
export function userSignIn(query) {
  return request({
    url: '/activeGinseng/userSignIn',
    method: 'get',
    params: query
  })
}
//审核拒绝-用户报名活动
export function refuseUserRegisterActivity(query) {
  return request({
    url: '/activeGinseng/refuseUserRegisterActivity',
    method: 'get',
    params: query

  })
}
//审核通过
export function auditUserRegisterActivity(query) {
  return request({
    url: '/activeGinseng/auditUserRegisterActivity',
    method: 'get',
    params: query

  })
}
//根据请求路径生成二维码
export function getQRCode(query) {
  return request({
    url: '/activeGinseng/basicQrcode',
    method: 'get',
    // responseType: "blob",
    params: query
  })
}
//活动查看人数一览
export function listUserCheckPage(query) {
  return request({
    url: '/activeGinseng/listUserCheckPage',
    method: 'get',
    params: query

  })
}
//活动顶部信息查询
export function listActiveTop(query) {
  return request({
    url: '/activeGinseng/listActiveTop',
    method: 'get',
    params: query

  })
}
//活动分享人数一览
export function listUserSharePage(query) {
  return request({
    url: '/activeGinseng/listUserSharePage',
    method: 'get',
    params: query

  })
}
//活动查看人数
export function userCheck(query) {
  return request({
    url: '/activeGinseng/userCheck',
    method: 'get',
    params: query

  })
}
//活动分享人数
export function userShare(query) {
  return request({
    url: '/activeGinseng/userShare',
    method: 'get',
    params: query

  })
}
